import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { processArticleContent, processSimpleHTMLContent } from '../../utils/processHTMLContent';
import { getAuthorDetails, getFaqs, getRelatedPosts } from '../../utils/helpers';
import { getComments } from '../../utils/helpers/comments';
import { getSpecialOffer } from '../../utils/helpers/card';
import { IFact } from '../../components/FullFeatured/FullFeatured.def';
import Article from '../../components/Article/Article';
import Seo from '../../components/Seo';
import { IAirlineLoyaltyProgramQuickFacts } from '../../models/IAirlineLoyaltyProgramQuickFacts';
import { TTemplate } from '../../models/TTemplate';
import { HT_DEFAULT_PAGE_NAME } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IDataTemplate {
  post: Queries.WpPost;
}

interface IPageContextTemplate {
  sidebar: Queries.WpUpOption;
  databaseId: number;
  tags: any;
  link: string;
  categories: any;
  specialOffer?: number;
}

const AirlineLoyaltyProgramTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const {
    post: {
      content = '',
      author,
      title,
      categories,
      modifiedGmt,
      dateGmt,
      featuredImage,
      commentCount,
      comments: postComments,
      commentStatus,
      template,
      cardMentionsAll,
      nonAffiliateAll,
      fieldsForPosts,
      databaseId,
    },
  } = data;
  const placing = '/TE/';
  const templateName: TTemplate = 'Full Width Hero Image + Sidebar';
  const { articleContent } = processArticleContent(content || '', placing, templateName);
  const sidebarGeneralSettings = pageContext?.sidebar;
  const authorDetails = getAuthorDetails(author?.node);
  const numberOfComments = commentCount ? commentCount : 0;
  const comments = getComments(postComments, databaseId);
  const faqs = getFaqs(fieldsForPosts?.upgpPostFaqs);
  const relatedPosts = getRelatedPosts(fieldsForPosts?.upgpPostRelatedPosts);
  const overrideSidebar = fieldsForPosts?.upgpOverrideDefaultSidebar ?? false;
  const sidebarPost = fieldsForPosts?.upgpCategorySidebarBlocks;
  const specialOffer = getSpecialOffer(pageContext?.specialOffer);
  const postCategories = categories ? categories.nodes : [];
  const facts: IFact[] = [];

  if ((template as any).airlineLoyaltyProgramDetails) {
    const airlineQuickFacts: IAirlineLoyaltyProgramQuickFacts = (template as any)
      .airlineLoyaltyProgramDetails;

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramHomeCountry) {
      facts.push({
        label: 'Home Country',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramHomeCountry,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramWebsite) {
      facts.push({
        label: 'Airline Website',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramWebsite,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramName) {
      facts.push({
        label: 'Loyalty Program',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramName,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramMajorHubs) {
      facts.push({
        label: 'Major Hubs',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramMajorHubs,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramAirlineAllianc) {
      facts.push({
        label: 'Airline Alliance',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramAirlineAllianc,
          placing,
        ),
      });
    }
    if (airlineQuickFacts.upgpAirlineLoyaltyProgramAwardChartLink) {
      facts.push({
        label: 'Award Chart Link',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramAwardChartLink,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramPointsAreWorth) {
      facts.push({
        label: 'Points Are Worth',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramPointsAreWorth,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramSignatureLounge) {
      facts.push({
        label: 'Signature Lounge',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramSignatureLounge,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramEarnPointsmiles) {
      facts.push({
        label: 'Earn Points/Miles',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramEarnPointsmiles,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramRedeemPointsmiles) {
      facts.push({
        label: 'Redeem Points/Miles',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramRedeemPointsmiles,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramReview) {
      facts.push({
        label: 'Airline Review',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramReview,
          placing,
        ),
      });
    }

    if (airlineQuickFacts.upgpAirlineLoyaltyProgramFees) {
      facts.push({
        label: 'Airline Fee',
        children: processSimpleHTMLContent(
          airlineQuickFacts.upgpAirlineLoyaltyProgramFees,
          placing,
        ),
      });
    }
  }

  const toggleElements: string[] =
    typeof fieldsForPosts?.upgpPostToggleElements?.length !== 'undefined'
      ? fieldsForPosts.upgpPostToggleElements.map((el) => el ?? '')
      : [];
  return (
    <HTPageWrapper
      category={'Travel, Airlines, Loyalty'}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <Article
        loyaltyHeader={{
          alt: title || '',
          image: featuredImage?.node?.sourceUrl || null,
          asideMeta: {
            mainTitle: title || '',
            headline: 'Airline Loyalty Rewards',
            author: {
              name: authorDetails.name,
            },
            lastUpdated: modifiedGmt || '',
            datePublished: dateGmt || '',
            authorLink: authorDetails.url,
            breadCrumbs: [
              {
                link: '/travel/airlines/',
                text: 'Airline',
              },
              {
                link: '/travel/airlines/',
                text: 'Rewards',
              },
            ],
          },
        }}
        articleContent={articleContent}
        authorInfo={{
          authorName: authorDetails.name,
          content: authorDetails.bio || '',
          link: authorDetails.url,
          socials: authorDetails.socials,
          thumbnail: {
            src: authorDetails.imageUrl,
            alt: authorDetails.imageAlt,
          },
        }}
        comments={{
          comments,
          postDatabaseId: databaseId,
          total: numberOfComments,
          commentsClosed: commentStatus === 'closed',
        }}
        cardMentions={cardMentionsAll}
        nonAffiliate={nonAffiliateAll}
        faqs={{
          faqs,
        }}
        postId={databaseId}
        relatedPosts={{
          relatedPosts,
        }}
        sidebarData={{
          articleId: databaseId,
          sidebar: sidebarGeneralSettings,
          categories: postCategories,
          overrideSidebarPost: overrideSidebar,
          sidebarPost: sidebarPost,
        }}
        template={templateName}
        weRecommend={specialOffer}
        pulse={{
          isPopup: false,
        }}
        quickFacts={{
          facts,
        }}
        toggleElements={toggleElements}
        context={{
          databaseId: data?.post?.databaseId || 0,
          tags: data?.post?.tags || null,
          link: data?.post?.uri || '',
          categories: data?.post?.categories || null,
          templateName: data?.post?.template?.templateName || '',
        }}
      />
    </HTPageWrapper>
  );
};

export default AirlineLoyaltyProgramTemplate;

export const Head = ({ data }: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const { title, seo, fieldsForPosts, categories } = data.post;
  return (
    <>
      <Seo
        title={title || ''}
        wpSeo={seo}
        faqs={fieldsForPosts?.upgpPostFaqs}
        categories={categories}
      />
    </>
  );
};

export const query = graphql`
  query AirlineLoyaltyProgram($id: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostData
    }
  }
`;
